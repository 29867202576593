<template>
    <div class="associated">
      <div class="associated_top">
        <div class="associated_img">
          <img :src="companyinfo.companyLogo" alt="" />
          <span style="padding-left: 10px">{{ companyinfo.companyFullName }}</span>
        </div>
      </div>
      <div class="switch_tab">
        <el-form ref="form" :model="queryInfo" label-width="100px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="姓名" label-width="80px" >
              <div class="input-with">
                
                <div>
                  <el-input
                    placeholder="请输入姓名"
                    v-model="queryInfo.name"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="getDiagnosticTools()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="诊断工具:" prop="evaluationType">
              <el-radio-group v-model="queryInfo.evaluationType" @change="getDiagnosticTools">
                <el-radio :label="'1'">企业数智化转型诊断评估</el-radio>
                <el-radio :label="'2'">企业数据化转型政策申报,</el-radio>
                <el-radio :label="'3'">调查问卷工具</el-radio>
              
              </el-radio-group>
              
            </el-form-item>
            <el-form-item label="提交状态:" prop="submitState">
              <el-radio-group @change="getDiagnosticTools" v-model="queryInfo.submitState">
                <el-radio :label="'0'">已保存</el-radio>
                <el-radio :label="'1'">已提交</el-radio>
               
              </el-radio-group>
              
            </el-form-item>
            
          </div>
        </div>
      </el-form>
        <div class="label_management">
          <el-table
        v-loading="loading"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="编号" align="center" type="index"></el-table-column>
       
        <el-table-column
          label="提交保存时间"
          sortable
          prop="createTime"
          align="center"
          min-width="140"
        ></el-table-column>
        <el-table-column label="工具类型" align="center" min-width="120">
          <template v-slot="{ row }">
            <span v-if=" row.evaluationType == '1'">
              企业数智化转型诊断评估
            </span>
            <span v-if=" row.evaluationType == '2'">企业数智化转型政策申报</span>
            <span v-if=" row.evaluationType == '3'">调查问卷工具</span>
          </template>
        </el-table-column>
        
        <el-table-column label="提交状态" prop="companyFullName" align="center" min-width="100">
          <template v-slot="{ row }">
            <span v-if="row.submitState == 0">已保存</span>
            <span style="color: #4e93fb" v-if="row.submitState == 1">已提交</span>
          </template>
        </el-table-column>
        
        <el-table-column label="姓名" prop="name" align="center" min-width="100"></el-table-column>
        <el-table-column
          label="职位"
          prop="position"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column label="电话" align="center" min-width="120">
          <template v-slot="{ row }">
            <span>{{ row.phone }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          min-width="140"
        >
          <template slot-scope="scope">
           
            <el-button style="color: cornflowerblue" type="text" @click="torecord(scope.row)"
              >详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
       
        </div>
      </div>
    
    
    
    </div>
  </template>
  
  <script>
  import {
    getCompanyDetail,
    listDiagnosePage
  } from '@/api/datement'
  export default {
    name: 'CorrelateUser',
    computed: {
      id() {
        return this.$route.query.id
      },
     
    },
    data() {
      return {
       
        queryInfo: {
          companyId:'',//企业编号
          orderByParam: '',
          evaluationType:'',//评估类型(1:企业数智化转型诊断评估,2:企业数据化转型政策申报,3->调查问卷工具)
          submitState:'',//提交状态（0：保存，1：提交）
          name:'',//姓名
          partnerChannelId:'',
          pageNum: 1,
          pageSize: 10
        },
        listData: [],
        total: 0,
        companyinfo: {},
       loading:false,
       parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),

        
      }
    },
    created() {
      this.getDiagnosticTools()
      this.getcompany()
     
    },
    methods: {
    
     
      //查询企业信息回显
      async getcompany() {
        const res = await getCompanyDetail({ id: this.id })
        if (res.data.resultCode == 200) {
          this.companyinfo = res.data.data
        }
      },
      //用户一览
      async getDiagnosticTools() {
        this.queryInfo.companyId=this.id
        this.queryInfo.partnerChannelId=this.parteninfo.id
        this.loading=true
        const res = await listDiagnosePage(this.queryInfo)
        if (res.data.resultCode == 200) {
          this.listData = res.data.data.list
          this.total=res.data.data.total
          this.loading=false
        }
      },
       // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
   //进入诊断详情
   torecord(row) {
      this.$router.push({
        path: '/customer/recordinfo',
        query: {
          id: row.webId,
          diagnoseId: row.id,
          questionnaireId: row.questionnaireId,
          isSelect: row.isSelect
        }
      })
    },
     
    }
  }
  </script>
  <style lang="less" scoped>
  ::v-deep .el_upload .el-form-item {
    margin-bottom: 0px;
  }
  ::v-deep .name_form .el-input__suffix {
    position: absolute;
    height: 100%;
    right: -54px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    pointer-events: none;
  }
  ::v-deep .el-step__title {
    font-size: 14px;
    line-height: 38px;
  }
  
  ::v-deep .name_form .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 41px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 556px;
  }
  ::v-deep .name_form .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 496px;
  }
  
  .associated {
    background: #ffffff;
    .img_commer {
      display: flex;
      border: 1px solid #146aff;
      border-radius: 39px;
      .name {
        font-size: 12px;
        line-height: 20px;
        color: #146aff;
      }
      .color_img {
        background: #146aff;
        height: 20px;
        width: 25px;
        border-radius: 20px;
        position: relative;
        left: -10px;
        img {
          width: 13px;
          height: 13px;
          position: relative;
          left: 5px;
          top: 2px;
        }
      }
    }
    .img_commers {
      display: flex;
      border: 1px solid #ff9c00;
      border-radius: 39px;
      margin: 0px 10px;
      .name {
        font-size: 12px;
        line-height: 20px;
        color: #ff9c00;
      }
      .color_img {
        background: #ff9c00;
        height: 20px;
        width: 25px;
        border-radius: 20px;
        position: relative;
        left: -10px;
        img {
          width: 13px;
          height: 13px;
          position: relative;
          left: 5px;
          top: 2px;
        }
      }
    }
    .associated_top {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
     padding-top: 10px;
      .account_cycle {
        text-align: right;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
  
        /* 灰色字体 */
  
        color: #969495;
      }
      .associated_img {
        display: flex;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        
        color: #151515;
        img {
          width: 67px;
          height: 54px;
          border-radius: 5px;
        }
      }
    }
    .switch_tab {
      background: #ffffff;
      margin-top: 20px;
      .comble_txt{
        display: flex;
        align-items: center;
      }
      .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    position: relative;
   
    .input-with-select {
      width: 300px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
      .essential_information {
        padding: 30px 40px;
        .general_input {
          width: 845px;
          border: 1px solid #d4d4d4;
          padding: 14px 16px;
  
          .input_flex {
            display: flex;
            justify-content: space-between;
            padding: 7px 0px;
            span {
              padding-left: 15px;
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
        .img_tips {
          position: relative;
          top: -10px;
          color: #6ba1ff;
        }
  
        h4 {
          color: #146aff;
        }
        .dialog-footer {
          display: flex;
          justify-content: center;
        }
      }
    }
  
    .label_management {
      padding: 10px 10px;
  
      .new_page {
        margin: 20px auto;
        width: 100%;
        text-align: center;
      }
  
      .input-with {
        width: 100%;
        overflow: hidden;
        padding: 20px 0px;
  
        .input-with-select {
          width: 400px;
          float: left;
        }
  
        .seach {
          background-color: #448aff;
          color: #fff;
          border-radius: 0;
          border: 1px solid #448aff;
        }
      }
    }
  }
  ::v-deep .input_width .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 415px;
  }
  </style>
  